import Layout from '@components/layout';
import Carousel from '@components/partials/common/carousel-cards';
import CarouselVideo from '@components/partials/common/carousel-cards/carouselVideo';
import IntroTextFull from '@components/partials/common/introTextFull';
import Pagination from '@components/partials/common/navigation/pagination';
import HeroBanner from '@components/partials/homePage/hero-banner/hero-banner-promo';
import LatestReviews from '@components/partials/homePage/latestReviews';
import DefaultSchema from '@components/partials/schemas/defaultSchema';
import { getDefaultData } from '@data/default';
import { getHolidayInfo } from '@data/holiday';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { MyContext } from '../context/provider';
import { findNextPageSlug, findPrevPageSlug } from '../lib/repository/pageFinder';
import { prepareFeaturedCard, prepareFeaturedTag } from '../lib/repository/thumbnail';
import { CategoryProps } from '../lib/types/categoryProps';
import { DefaultApiType, DefaultProps, HomeApiType } from '../lib/types/defaultProps';
import { holidayProps } from '../lib/types/holidayProps';
import { paginationLimit } from '../lib/web/config';

type Props = {
    holidayMenuContent: holidayProps;
    defaultData: DefaultProps;
    defaultApiData: DefaultApiType;
    categoriesData: CategoryProps;
    reviewLinks: HomeApiType;
    categoryLinks: HomeApiType;
    queries: any;
    homepageReviewData: { text: string };
    featuredData: any;
    homePromoText: string;
    partnerVideos: any;
};

export default function Index(props: Props): JSX.Element {
    const router = useRouter();
    const { theme } = MyContext();
    const [homepageReviewData, setHomepageReviewData] = useState(prepareFeaturedTag(props.homepageReviewData, 'light') || []);
    const [featuredCard, setFeaturedCard] = useState(prepareFeaturedCard(props.featuredData.text, 'light') || []);

    const metaDescription = `Enter the most read porn review site! Rabbits porn reviews examines the best premium porn sites. Read honest porn site reviews!`;
    const metaTitle = `Porn Websites & Reviews of the Best Premium Porn Sites | Rabbits Review`;
    const h1Title = `Porn Websites & Reviews of the Best Premium & Paid Porn Sites`;

    useEffect(() => {
        // This is a special case for when the user clicks on the back button
        // When you click on the back button, the query parameter doesn't get pass down properly
        // So I had to do a manual refresh here for those cases
        // In here we check if there's a discrepancy between how many page queries the server finds
        // vs the client finds. Run a reloads if there's one.
        if (Object.keys(router.query).length > 0 && Object.keys(props.queries).length === 0) {
            router.reload();
        }
    }, [props.queries]);

    // This effect updates the homepage review data when the theme changes
    useEffect(() => {
        setHomepageReviewData(prepareFeaturedTag(props.homepageReviewData, theme));
        setFeaturedCard(prepareFeaturedCard(props.featuredData.text, theme));
    }, [theme]);

    const currentDate = new Date();
    const filteredPartnerVideos = props.partnerVideos.videos.map((video) => {
        const videoDate = new Date(video.datePublished);
        return {
            ...video,
            isUpcoming: videoDate > currentDate
        };
    });

    return (
        <Layout holiday={props.holidayMenuContent}>
            <Head>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={props.defaultData.metaKeywords} />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:image" content="https://assets.rabbitsreviews.com/images/rr-logo/00-default/OGLogo.png" />
                <meta property="og:url" name="title" content={process.env.NEXT_PUBLIC_APP_DOMAIN + router.asPath} />
                <meta name="robots" content={props.defaultData.metaRobots} />
                <link rel="canonical" href={process.env.NEXT_PUBLIC_APP_DOMAIN + router.asPath} />
                {/* finds the next page to construct '<link rel="next" href="/?page=${nextPageNumber}"/>' */}
                {Number(router.query.page) !== Math.ceil(Number(props.defaultApiData.latestReviewsCount / paginationLimit)) &&
                props.defaultApiData.latestReviewsCount > paginationLimit ? (
                    <link rel="next" href={findNextPageSlug(router)} />
                ) : (
                    ''
                )}

                {/* finds the next page to construct '<link rel="prev" href="/?page=${prevPageNumber}">' */}
                {router.query.page && Number(router.query.page) > 1 ? <link rel="prev" href={findPrevPageSlug(router)} /> : ''}

                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(DefaultSchema()) }} />
            </Head>
            <HeroBanner
                title={h1Title}
                text={props.homePromoText}
                data={props.defaultApiData.banners}
                isFirstPage={router.query.page == undefined || Number(router.query.page) === 1}
            />
            {/* Featured Porn Sites Section */}
            {router.query.page && featuredCard ? (
                ''
            ) : (
                <Carousel type="featured" items={{ links: featuredCard, title: 'Featured Porn Sites' }} />
            )}
            {/* Most Popular Porn Reviews Section */}
            {router.query.page && homepageReviewData ? '' : <Carousel type="tag" items={homepageReviewData} />}
            {/* Best Porn Sites by Category Section*/}
            {router.query.page && props.categoryLinks ? '' : <Carousel type="card" items={props.categoryLinks} />}
            {props.queries.page > 1 || !filteredPartnerVideos.length ? (
                ''
            ) : (
                <CarouselVideo
                    type="video"
                    isFilterToggle={true}
                    items={{
                        links: filteredPartnerVideos,
                        title: 'Latest Porn Videos'
                    }}
                    style={{ type: 'homepage-linkout' }}
                />
            )}
            <LatestReviews latestReviews={props.defaultApiData.latestReviews} holiday={props.holidayMenuContent} />{' '}
            {props.queries.page > 1 ? (
                ''
            ) : (
                <div className={'homepageTagCloud'}>
                    <Carousel type="tag" items={props.defaultApiData.categoryTops} />
                </div>
            )}
            <Pagination numberOfSites={props.defaultApiData.latestReviewsCount} />
            <IntroTextFull
                introTitle={props.defaultApiData.intro.title.content}
                introContent={props.defaultApiData.intro.body.content}
            />
        </Layout>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    const defaultData = await getDefaultData();
    const queries = await context.query;
    const pageQuery = queries && queries.page ? `&offset=${(Number(queries.page) - 1) * paginationLimit}` : '';
    const defaultApiHit = await fetch(process.env.NEXT_PUBLIC_API_DOMAIN + `/v2/sites/home?limit=${paginationLimit}${pageQuery}`);
    const defaultApiData = await defaultApiHit.json();

    // Refactored holiday menu here - this way it will be build server-side
    const holidayMenuContent = await getHolidayInfo();

    const categoryApiHit = await fetch(process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/sites/categories');
    const categoriesData = await categoryApiHit.json();

    const featuredData = await fetch(process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/snippet/homepage-featured-sites').then((res) =>
        res.json()
    );

    const homeApiHit = await fetch(process.env.NEXT_PUBLIC_API_DOMAIN + '/v2/sites/home').then((res) => res.json());
    const { reviewLinks, categoryLinks } = homeApiHit;

    const homepageReview = await fetch(process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/snippet/homepage-review-links').then((res) =>
        res.json()
    );
    const { text: homepageReviewData } = homepageReview;

    //Fetching in the descriptive text below the H1 in the promo section
    let homePromoText = '';
    const homePromoTextApiHit = await fetch(process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/snippet/default-page-intro-top');
    const homePromoTextData = await homePromoTextApiHit.json();

    const partnerVideosHit = await fetch(process.env.NEXT_PUBLIC_API_DOMAIN + `/v1/allvideos?limit=22`);
    const partnerVideos = await partnerVideosHit.json();
    //If it errors out & snippet is empty, it should be an empty string
    homePromoText = homePromoTextData && !homePromoTextData.error ? homePromoTextData.text : '';

    if (!defaultApiData.latestReviews || defaultApiData.latestReviews.length === 0) {
        return {
            notFound: true
        };
    }
    return {
        props: {
            holidayMenuContent,
            defaultData,
            defaultApiData,
            featuredData,
            categoriesData,
            reviewLinks,
            homepageReviewData,
            categoryLinks,
            queries,
            homePromoText,
            partnerVideos
        }
    };
};
